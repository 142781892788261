import request from "@/utils/request";

export function getAllTags(page = 1){
  return request(
    {
      url: `tags?page=${page}`,
      method: "get"
    }
  )
}

export function searchTag(data) {
  return request(
    {
      url: "tags/search",
      method: "post",
      data
    }
  )
}

export function createTag(data) {
  return request(
    {
      url: "tags",
      method: "post",
      data
    }
  )
}

export function getTag(tag_id) {
  return request(
    {
      url: `tags/${tag_id}`,
      method: "get"
    }
  )
}


export function editTag(tag_id,data){
  return request(
    {
      url: `tags/${tag_id}`,
      method: "put",
      data
    }
  )
}
