<template>
  <div class="container-fluid mt-3">
    <div class="d-flex align-items-sm-stretch align-items-end justify-content-between flex-wrap">
      <div class="d-flex flex-wrap align-items-lg-center mb-1 flex-sm-row flex-column justify-content-between flex-grow-1">

        <Breadcrumb :current_page="` جميع الأوسمة (${total})` "/>
        <router-link class="el-button el-button--success el-button--default btn-add ms-2" to="/add-tag">
          إضافة وسم جديد
        </router-link>
      </div>


      <collapse-filter>
        <form action="" @submit.prevent="handleFilterTag" class="d-flex gap-3 flex-wrap filter-form">
          <div class="form-group align-items-baseline flex-lg-row flex-column gap-2 d-flex">
            <label>بحث بحسب ال id</label>
            <input type="text" v-model="id" class="form-control search_by_id"/>
          </div>

          <div class="form-group align-items-baseline flex-lg-row flex-column gap-2 d-flex">
            <label>العنوان</label>
            <input type="text" v-model="name" class="form-control"/>
          </div>
          <div class="form-group align-self-end align-self-lg-auto">
            <button class="btn btn-search px-3" type="submit" @click="handleFilterTag" :disabled="loading">
              بحث
            </button>
          </div>
        </form>
      </collapse-filter>

    </div>



    <el-card class="mt-2">
      <el-table id="top-table" :data="tags" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">
        <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
        <el-table-column prop="name"  label="الاسم" width="150"/>
        <el-table-column prop="no_of_uses"  label="عدد الاستخدمات" width="150"/>
        <el-table-column label="الأدوات" align="center" width="120" class-name="control-btns">
          <template #default="scope">
            <el-tooltip class="item" effect="dark" content="تعديل الوسم" placement="top">
              <router-link :to="`/edit-tag/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
              </router-link>
            </el-tooltip>

<!--            <el-popconfirm-->
<!--                hide-icon-->
<!--                confirmButtonType="danger"-->
<!--                cancelButtonType="default"-->
<!--                confirmButtonText="نعم"-->
<!--                cancelButtonText="لا"-->
<!--                iconColor="red"-->
<!--                @confirm="confirmDelete(scope.row.id)"-->
<!--                title="هل أنت متأكد أنك تريد حذف هذا الوسم؟">-->
<!--              <template #reference>-->
<!--                <el-button title="حذف الوسم" class="py-1 px-2 btn btn-outline-danger">-->
<!--                  <i class="far fa-trash-alt"/>-->
<!--                </el-button>-->
<!--              </template>-->
<!--            </el-popconfirm>-->
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="tags.length" v-model="page" :records="total" :per-page="per_page" :options="paginationOptions"
                  @paginate="fetchAllTags"/>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import Loader from "@/components/Loader";
import {getAllTags, searchTag} from "@/services/tags";
import CollapseFilter from "../../components/CollapseFilter";

export default {
  name      : "all_tags",
  components: {
    CollapseFilter,
    Loader,
    Breadcrumb
  },
  data()
  {
    return {
      loading          : false,
      id               : '',
      name             : '',
      tags             : [],
      page             : 1,
      total            : 0,
      per_page         : 1,
      paginationOptions: {
        chunk: 5,
        theme: "bootstrap4",
        //hideCount: true
      }

    }
  },
  mounted()
  {
    this.fetchAllTags();
  },
  methods: {
    fetchAllTags()
    {
      this.loading = true;
      getAllTags(this.page)
          .then(res => {
            this.loading  = false
            this.total    = res.data.total
            this.per_page = res.data.per_page
            this.tags     = res.data.data
          })
          .catch(() => {
            this.loading = false

          })
    },
    handleFilterTag()
    {
      searchTag({name: this.name, id: this.id})
          .then(res => {
            console.log(res)
          })
          .catch(e => {
            console.log(e)
          })
    },
    confirmDelete(id)
    {

    }
  }
}
</script>


<style lang="scss">
.form-group.d-flex:first-of-type {
  label {
    width: fit-content;
    width: -moz-fit-content;
  }
}

table {
  table-layout: fixed;
}

.custom-width {
  width: 150px;
}

.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}

</style>
